import {
  faDownload,
  faEye,
  faEyeSlash,
  faHandshake,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse, Switch, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AccountCard from '../account/AccountCard';
import { EntityActionButtons } from '../shared/EntityActionButtons';
import './styles/accountGroupsCollapsible.scss';
import { AccountGroup } from '../../@types/AccountGroup';
import DeleteGroupModal from './DeleteGroupModal';
import AccountGroupFormModal from './AccountGroupFormModal';
import {
  getCachedAccountGroups,
  refreshAccountGroups,
} from '../helpers/storeHelper';
import AccountFormModal from '../account/AccountFormModal';
import { concat, filter, includes, isEmpty, without } from 'lodash';
import { Account } from '../../@types/Account';
import {
  convertApiDateStringToDisplayString,
  isSharedWithMeGroup,
} from '../../utils/helpers';
import { downloadCSV } from '../../utils/csvHelpers';

interface AccountGroupsCollapsibleState {
  globalArchivedMode: boolean;
  inArchivedMode: number[];
  selectedAccountGroup: AccountGroup | null;
  accountFormModalVisible: boolean;
  accountGroupFormModalVisible: boolean;
  deleteAccountGroupModalVisible: boolean;
  csvLoading: boolean;
}

const AccountGroupsCollapsible = ({}) => {
  const { t } = useTranslation();
  const accountGroups = getCachedAccountGroups();

  const [state, setState] = useState<AccountGroupsCollapsibleState>({
    globalArchivedMode: false,
    inArchivedMode: [],
    selectedAccountGroup: null,
    accountFormModalVisible: false,
    accountGroupFormModalVisible: false,
    deleteAccountGroupModalVisible: false,
    csvLoading: false,
  });

  useEffect(() => {
    // refresh cached data!
    refreshAccountGroups();
  }, []);

  const closeModals = () => {
    setState({} as any);
  };

  /*
   * Add Account Modal
   */

  const onSaveAccount = async (_mutationInfo: any) => {
    await refreshAccountGroups();
    closeModals();
  };

  const accountFormModal = state.accountFormModalVisible && (
    <AccountFormModal
      accountGroup={state.selectedAccountGroup!}
      onSave={onSaveAccount}
      onCancel={closeModals}
    />
  );

  const openAccountFormModal = (accountGroup: AccountGroup) => {
    setState((state) => ({
      ...state,
      selectedAccountGroup: accountGroup,
      accountFormModalVisible: true,
    }));
  };

  /*
   * Update Account Group Modal
   */
  const onSaveAccountGroup = async (_mutationInfo: any) => {
    await refreshAccountGroups();
    closeModals();
  };

  const accountGroupFormModal = state.accountGroupFormModalVisible && (
    <AccountGroupFormModal
      accountGroup={state.selectedAccountGroup}
      onSave={onSaveAccountGroup}
      onCancel={closeModals}
    />
  );

  const openAccountGroupFormModal = (accountGroup: AccountGroup) => {
    setState((state) => ({
      ...state,
      selectedAccountGroup: accountGroup,
      accountGroupFormModalVisible: true,
    }));
  };

  /*
   *  Delete Account Group Modal
   */
  const onDeleteAccountGroup = async () => {
    await refreshAccountGroups();
    closeModals();
  };

  const deleteAccountGroupModal = state.deleteAccountGroupModalVisible && (
    <DeleteGroupModal
      accountGroup={state.selectedAccountGroup!}
      onDelete={onDeleteAccountGroup}
      onCancel={closeModals}
    />
  );

  const openDeleteGroupModal = (accountGroup: AccountGroup) => {
    setState((state) => ({
      ...state,
      selectedAccountGroup: accountGroup,
      deleteAccountGroupModalVisible: true,
    }));
  };

  /*
   * Add Account Button
   */
  const addAccountButton = (accountGroup: AccountGroup) => (
    <Button
      className="account-groups-collapsible__add-account__button"
      onClick={() => openAccountFormModal(accountGroup)}
    >
      <FontAwesomeIcon icon={faPlus} color="green" />
      <span>{t('account.actions.add')}</span>
    </Button>
  );

  /*
   * Show Archived Button
   */

  const setArchivedAccountsButton = (
    accountGroup: AccountGroup,
    archivedMode: boolean,
  ) => {
    setState((state) => {
      let inArchivedMode: number[];
      if (archivedMode) {
        inArchivedMode = concat(state.inArchivedMode, [accountGroup.id]);
      } else {
        inArchivedMode = without(state.inArchivedMode, accountGroup.id);
      }
      return {
        ...state,
        inArchivedMode: inArchivedMode,
      };
    });
  };

  const filteredAccountsList = (accountGroup: AccountGroup) => {
    const accounts = accountGroup!.accounts || [];

    // don't filter accounts if we are showing only archived account groups (i.e. the whole account group is archived)
    if (state.globalArchivedMode) {
      return accounts;
    }

    const archivedMode = includes(state.inArchivedMode, accountGroup.id);
    return (
      filter(accounts, (account) => account.archived === archivedMode) ||
      ([] as Account[])
    );
  };

  const filteredAccountGroupsList = () => {
    return (
      filter(
        accountGroups,
        (accountGroup) =>
          accountGroup.archived === (state.globalArchivedMode || false),
      ) || ([] as AccountGroup[])
    );
  };

  const setArchivedAccountGroupsButton = (globalArchivedMode: boolean) => {
    setState((state) => {
      return {
        ...state,
        globalArchivedMode: globalArchivedMode,
      };
    });
  };

  const archivedAccountGroupsModeSwitch = () => {
    const archivedAccountGroupsCount =
      filter(
        accountGroups || [],
        (accountGroup) => accountGroup.archived == true,
      )?.length || 0;

    const switchLabel = `${t(
      'accountGroup.actions.showArchived',
    )} (${archivedAccountGroupsCount})`;

    return (
      <Switch
        className="account-groups-collapsible__panel__action-buttons__archived-mode"
        onChange={(enabled) => setArchivedAccountGroupsButton(enabled)}
        checkedChildren={
          <span>
            {' '}
            <FontAwesomeIcon icon={faEye} />
            {switchLabel}
          </span>
        }
        unCheckedChildren={
          <span>
            <FontAwesomeIcon icon={faEyeSlash} color="gray" />
            {switchLabel}
          </span>
        }
        checked={state.globalArchivedMode}
      />
    );
  };

  const archivedAccountsModeSwitch = (accountGroup: AccountGroup) => {
    const archivedAccountsCount =
      filter(accountGroup.accounts || [], (account) => account.archived == true)
        ?.length || 0;

    const switchLabel = `${t(
      'accountGroup.actions.showArchived',
    )} (${archivedAccountsCount})`;

    return (
      <Switch
        className="account-groups-collapsible__panel__action-buttons__archived-mode"
        onChange={(enabled) => setArchivedAccountsButton(accountGroup, enabled)}
        checkedChildren={
          <span>
            {' '}
            <FontAwesomeIcon icon={faEye} />
            {switchLabel}
          </span>
        }
        unCheckedChildren={
          <span>
            <FontAwesomeIcon icon={faEyeSlash} color="gray" />
            {switchLabel}
          </span>
        }
        checked={includes(state.inArchivedMode, accountGroup.id)}
      />
    );
  };

  const prepareCSVRows =
    (accountGroup: AccountGroup, accountsList: Account[]) => async () => {
      setState((oldState) => ({ ...oldState, csvLoading: true }));

      const csvRows = [[]] as [string[]];

      csvRows.push([accountGroup.name]);

      // a couple of empty rows
      csvRows.push(['', '']);
      csvRows.push(['', '']);

      // headers
      csvRows.push([
        t('account.attributes.name'),
        t('generic.words.currency'),
        t('stats.totalBalance'),
        t('account.attributes.lastTransaction'),
      ]);

      for (const account of accountsList) {
        const accountCurrencyString = t(`account.currency.${account.currency}`);
        csvRows.push([
          account.name,
          accountCurrencyString,
          Number(account.balance || 0).toLocaleString(),
          account.last_transaction
            ? convertApiDateStringToDisplayString(account.last_transaction.date)
            : '',
        ]);
      }

      setState((oldState) => ({ ...oldState, csvLoading: false }));

      return csvRows;
    };

  /*
   * other ui components
   */

  const exportAccountsListToCSVButton = (
    accountGroup: AccountGroup,
    accountsList: Account[],
  ) => {
    const filename = `${accountGroup.name}_${new Date().toLocaleDateString()}`;

    return (
      <Tooltip
        placement="topLeft"
        title={t(`generic.actions.export`)}
        arrow={{ pointAtCenter: true }}
      >
        <Button
          className="entity-action-buttons__button"
          onClick={async () =>
            downloadCSV(filename, prepareCSVRows(accountGroup, accountsList))
          }
          loading={state.csvLoading}
          disabled={state.csvLoading}
        >
          <FontAwesomeIcon icon={faDownload} color="green" />
        </Button>
      </Tooltip>
    );
  };

  const accountGroupPanelActionButtons = (
    accountGroup: AccountGroup,
    isSharedGroup = false,
  ) => (
    <div
      className="account-groups-collapsible__panel__action-buttons"
      onClick={(event) => event.stopPropagation()}
    >
      {!isSharedGroup && addAccountButton(accountGroup)}
      {!state.globalArchivedMode && archivedAccountsModeSwitch(accountGroup)}
    </div>
  );

  const accountGroupCollapsePanel = (accountGroup: AccountGroup) => {
    const isSharedGroup = isSharedWithMeGroup(accountGroup);
    const accountGroupName = isSharedGroup
      ? t('dashboard.sharedWithMeGroup')
      : accountGroup.name;
    const accountsList = filteredAccountsList(accountGroup!);

    const extraButtons = (
      <>
        {!isEmpty(accountsList) &&
          exportAccountsListToCSVButton(accountGroup, accountsList)}
      </>
    );

    return {
      key: `collapse_panel_${accountGroup.id || Math.random() * 1000}`,
      label: accountGroupName,
      extra: !isSharedGroup ? (
        <EntityActionButtons
          ownerEntity={accountGroup}
          translationIndex="accountGroup"
          onEditButton={openAccountGroupFormModal}
          onDeleteButton={openDeleteGroupModal}
          extraButtons={extraButtons}
        />
      ) : (
        <FontAwesomeIcon icon={faHandshake} />
      ),
      children: (
        <>
          {accountGroupPanelActionButtons(accountGroup, isSharedGroup)}
          {accountsList.map((account: any) => {
            return (
              <div
                key={`account_${account.id}_card`}
                className="account-groups-collapsible__item"
              >
                <AccountCard account={account} />
              </div>
            );
          })}
        </>
      ),
    };
  };

  return (
    <>
      {accountFormModal}
      {accountGroupFormModal}
      {deleteAccountGroupModal}
      {archivedAccountGroupsModeSwitch()}
      <Collapse
        className="account-groups-collapsible"
        key="0-1"
        onChange={(key) =>
          localStorage.setItem('activePanels', JSON.stringify(key))
        }
        defaultActiveKey={JSON.parse(
          localStorage.getItem('activePanels') || '[]',
        )}
        items={filteredAccountGroupsList()?.map((accountGroup) =>
          accountGroupCollapsePanel(accountGroup),
        )}
      />
    </>
  );
};

export default AccountGroupsCollapsible;
